import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import { Button, Carousel } from 'react-bootstrap';
import { EmailSignUpBanner, SocialsVert, GPT } from '../Globals';
import { MediaCard } from './Media';
import { ReviewCard } from './Reviews';
import { EventCard } from './Events';

function PromoBanner() {
  return (
    <div className="container-fluid m-0 mb-4 row align-items-center justify-content-center bg-blue px-1 px-md-auto">
      <div className="container row col-6 col-md-3 pt-4 pt-md-0 py-1 px-0 md-appear">
        <img src="/img/onr_3d_book.png" alt="Our Next Reality book cover" className="col-12 mt-2 px-0"/>
        <div className="container d-flex justify-content-center col-3 mt-3">
          <div className="col-9 me-3">
            <a href="https://www.hachette.co.uk/titles/alvin-wang-graylin/our-next-reality/9781399812283/" target="_blank" rel="noopener noreferrer">
              <img src="/img/hachette_b.png" alt="Hachette Books logo" className="bg-gray round" />
            </a>
          </div>
          <div className="col-9 me-3">
            <a href="https://www.johnmurraypress.co.uk/titles/alvin-wang-graylin/our-next-reality/9781399812269/" target="_blank" rel="noopener noreferrer">
              <img src="/img/nb_p.jpg" alt="Nicholas Brealey Publishing logo" className="round" />
            </a>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-7 row d-flex justify-content-center">
        <h1 className="col-12 bg-blue pt-5 text-center"><em>Our Next Reality</em></h1>
        {/* <h1 className="col-md-12 bg-blue pt-5 text-center"><em>Coming Soon...</em></h1> */}
        {/* <h1 className="col-md-12 bg-blue pt-5 text-center hidden"><cite>Our Next Reality</cite> <span className="sub">&ensp;...coming soon</span></h1> */}
        <div className="row d-flex justify-content-center d-md-none">
          <div className="col-12 d-flex justify-content-center">
            <img src="/img/onr_3d_book.png" alt="Our Next Reality book cover" className="col-11 col-md-7 py-1 px-0"/>
          </div>
        </div>
        <h2 className="bg-blue pb-1 text-center">
          <span className="col-12 col-md-auto d-block d-md-inline">
            <span className="material-icons main-icon">tablet_android</span> <span className="suba">eBook</span> <span className="md-appear d-md-inline">· {' '}</span>
          </span>
          <span className="col-12 col-md-auto d-block d-md-inline">
            <span className="material-icons main-icon">headphones</span> <span className="suba">Audiobook</span> <span className="md-appear d-md-inline">· {' '}</span>
          </span>
          <span className="col-12 col-md-auto d-block d-md-inline">
            <span className="material-symbols-outlined main-icon">import_contacts</span> <span className="suba">Hardcover</span>
          </span>
        </h2>
        {/* <h2 className="bg-blue pb-3 row text-center">
          <span className="col-12 col-md-auto">
            <span className="material-icons main-icon">tablet_android</span> <span className="suba">Kindle</span> <span className="sub">(Mar 5th)</span> <span className="md-appear d-md-inline">· {' '}</span>
          </span>
          <span className="col-12 col-md-auto">
            <span className="material-icons main-icon">headphones</span> <span className="suba">Audible</span> <span className="sub">(Mar 6th)</span> <span className="md-appear d-md-inline justify-content-center">· {' '}</span>
          </span>
          <span className="col-12 col-md-auto">
            <span className="material-symbols-outlined main-icon">import_contacts</span> <span className="suba">Book</span> <span className="sub">(Jun 4th)</span>
          </span>
        </h2> */}

        <p className="subhead text-center pb-md-3">Published by <strong><a href="https://www.hachette.co.uk/titles/alvin-wang-graylin/our-next-reality/9781399812283/" target="_blank" rel="noopener noreferrer"><cite>Hachette Books / Nicholas Brealey Publishing</cite></a></strong></p>
        <div className="container d-flex d-md-none justify-content-center col-3 mt-md-3 pb-3">
          <div className="col-9 me-3">
            <a href="https://www.hachette.co.uk/titles/alvin-wang-graylin/our-next-reality/9781399812283/" target="_blank" rel="noopener noreferrer">
              <img src="/img/hachette_b.png" alt="Hachette Books logo" className="bg-gray round" />
            </a>
          </div>
          <div className="col-9 me-3">
            <a href="https://www.johnmurraypress.co.uk/titles/alvin-wang-graylin/our-next-reality/9781399812269/" target="_blank" rel="noopener noreferrer">
              <img src="/img/nb_p.jpg" alt="Nicholas Brealey Publishing logo" className="round" />
            </a>
          </div>
        </div>
        
        <p className="subhead">Over the last 100 years, technology has changed our world. Over the next decade it will transform our reality. The planet is headed for a momentous upheaval, and we are not prepared.</p>
        <p>In <cite>Our Next Reality</cite>, two industry veterans provide a data-driven debate on whether the new world we're creating will be a technological utopia or an AI-powered dystopia and gives guidance on how to aim for the best future we can. With a Foreword by renowned author Neal Stephenson and section contributions from industry thought-leaders such as Peter H. Diamandis, Tom Furness, Phillip Rosedale, Tony Parisi, Avi Bar Zeev and Walter Parkes, this book answers over a dozen of the most pressing questions we face as AI/VR/AR accelerates the digitization of our world. Find out why our actions in the next decade could determine the trajectory of our species for countless millennia. <Link to="book"><Button variant="light" size="sm" className="px-2">See More</Button></Link></p>
        
        <div className="row d-flex justify-content-center align-items-stretch mt-2">
          <div className="col-auto col-md-3 d-flex align-items-center justify-content-center me-md-3">
            <HashLink to="book#endorsements"><Button variant="light" size="lg" className="px-4 px-md-4 mb-1">See Reviews</Button></HashLink>
          </div>

          {/* Ebook */}
          <div className="bg-yellow container row col-10 col-md-3 d-flex align-items-center justify-content-center py-2 me-md-3">
            <p className="subhead text-center mb-1">Order eBook</p>

            <div className="d-flex justify-content-center mb-1 px-0 mx-0">
              <Button href="https://www.amazon.com/dp/1399812246/ref=cm_sw_r_as_gl_api_gl_i_CWSY31NM2VZ2AH3GHH04?linkCode=ml1&tag=tag02b-20" target="_blank" rel="noopener noreferrer" variant="light" size="lg" className=" px-md-3 me-1 me-md-2">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <title>Kindle</title>
                  <path fill="#FF9900" d="M257.2 162.7c-48.7 1.8-169.5 15.5-169.5 117.5 0 109.5 138.3 114 183.5 43.2 6.5 10.2 35.4 37.5 45.3 46.8l56.8-56S341 288.9 341 261.4V114.3C341 89 316.5 32 228.7 32 140.7 32 94 87 94 136.3l73.5 6.8c16.3-49.5 54.2-49.5 54.2-49.5 40.7-.1 35.5 29.8 35.5 69.1zm0 86.8c0 80-84.2 68-84.2 17.2 0-47.2 50.5-56.7 84.2-57.8v40.6zm136 163.5c-7.7 10-70 67-174.5 67S34.2 408.5 9.7 379c-6.8-7.7 1-11.3 5.5-8.3C88.5 415.2 203 488.5 387.7 401c7.5-3.7 13.3 2 5.5 12zm39.8 2.2c-6.5 15.8-16 26.8-21.2 31-5.5 4.5-9.5 2.7-6.5-3.8s19.3-46.5 12.7-55c-6.5-8.3-37-4.3-48-3.2-10.8 1-13 2-14-.3-2.3-5.7 21.7-15.5 37.5-17.5 15.7-1.8 41-.8 46 5.7 3.7 5.1 0 27.1-6.5 43.1z"/>
                  <path fill="#000000" d="M257.2 162.7c-48.7 1.8-169.5 15.5-169.5 117.5 0 109.5 138.3 114 183.5 43.2 6.5 10.2 35.4 37.5 45.3 46.8l56.8-56S341 288.9 341 261.4V114.3C341 89 316.5 32 228.7 32 140.7 32 94 87 94 136.3l73.5 6.8c16.3-49.5 54.2-49.5 54.2-49.5 40.7-.1 35.5 29.8 35.5 69.1zm0 86.8c0 80-84.2 68-84.2 17.2 0-47.2 50.5-56.7 84.2-57.8v40.6z"/>
                </svg>
              </Button>

              <Button href="https://books.apple.com/us/book/our-next-reality/id6469685058" target="_blank" rel="noopener noreferrer" variant="light" size="lg" className="px-md-3">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                  <title>Apple Books</title>
                  <path fill="#999999" d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"/>
                  {/* <path fill="#666666" d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"/> */}
                  {/* <path fill="#A3AAAE" d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"/> */}
                </svg>
              </Button>
            </div>
          </div>

          {/* Audiobook */}
          <div className="bg-yellow container row col-10 col-md-3 d-flex align-items-center justify-content-center p-2 me-md-3">
            <p className="subhead text-center mb-1">Order Audiobook</p>

            <div className="d-flex justify-content-center mb-1">
              <Button href="https://www.audible.com/pd/Our-Next-Reality-Audiobook/B0CLD7DW5D" target="_blank" rel="noopener noreferrer" variant="light" size="lg" className="px-md-3 me-1 me-md-2">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <title>Audible</title>
                  <path fill="#FF9900" d="M257.2 162.7c-48.7 1.8-169.5 15.5-169.5 117.5 0 109.5 138.3 114 183.5 43.2 6.5 10.2 35.4 37.5 45.3 46.8l56.8-56S341 288.9 341 261.4V114.3C341 89 316.5 32 228.7 32 140.7 32 94 87 94 136.3l73.5 6.8c16.3-49.5 54.2-49.5 54.2-49.5 40.7-.1 35.5 29.8 35.5 69.1zm0 86.8c0 80-84.2 68-84.2 17.2 0-47.2 50.5-56.7 84.2-57.8v40.6zm136 163.5c-7.7 10-70 67-174.5 67S34.2 408.5 9.7 379c-6.8-7.7 1-11.3 5.5-8.3C88.5 415.2 203 488.5 387.7 401c7.5-3.7 13.3 2 5.5 12zm39.8 2.2c-6.5 15.8-16 26.8-21.2 31-5.5 4.5-9.5 2.7-6.5-3.8s19.3-46.5 12.7-55c-6.5-8.3-37-4.3-48-3.2-10.8 1-13 2-14-.3-2.3-5.7 21.7-15.5 37.5-17.5 15.7-1.8 41-.8 46 5.7 3.7 5.1 0 27.1-6.5 43.1z"/>
                  <path fill="#000000" d="M257.2 162.7c-48.7 1.8-169.5 15.5-169.5 117.5 0 109.5 138.3 114 183.5 43.2 6.5 10.2 35.4 37.5 45.3 46.8l56.8-56S341 288.9 341 261.4V114.3C341 89 316.5 32 228.7 32 140.7 32 94 87 94 136.3l73.5 6.8c16.3-49.5 54.2-49.5 54.2-49.5 40.7-.1 35.5 29.8 35.5 69.1zm0 86.8c0 80-84.2 68-84.2 17.2 0-47.2 50.5-56.7 84.2-57.8v40.6z"/>
                </svg>
              </Button>

              <Button href="https://books.apple.com/us/audiobook/our-next-reality/id1724319743" target="_blank" rel="noopener noreferrer" variant="light" size="lg" className="px-md-3">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                  <title>Apple Audiobooks</title>
                  <path fill="#999999" d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"/>
                  {/* <path fill="#666666" d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"/> */}
                  {/* <path fill="#A3AAAE" d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"/> */}
                </svg>
              </Button>
            </div>
          </div>

          {/* Hardcover */}
          <div className="bg-yellow container row col-10 col-md-3 d-flex align-items-center justify-content-center p-2">
            <p className="subhead text-center mb-1">Order Hardcover</p>

            <div className="d-flex justify-content-center mb-1">
              <Button href="https://www.amazon.com/dp/1399812246/ref=cm_sw_r_as_gl_api_gl_i_CWSY31NM2VZ2AH3GHH04?linkCode=ml1&tag=tag02b-20" target="_blank" rel="noopener noreferrer" variant="light" size="lg" className=" px-md-3">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <title>Amazon</title>
                  <path fill="#FF9900" d="M257.2 162.7c-48.7 1.8-169.5 15.5-169.5 117.5 0 109.5 138.3 114 183.5 43.2 6.5 10.2 35.4 37.5 45.3 46.8l56.8-56S341 288.9 341 261.4V114.3C341 89 316.5 32 228.7 32 140.7 32 94 87 94 136.3l73.5 6.8c16.3-49.5 54.2-49.5 54.2-49.5 40.7-.1 35.5 29.8 35.5 69.1zm0 86.8c0 80-84.2 68-84.2 17.2 0-47.2 50.5-56.7 84.2-57.8v40.6zm136 163.5c-7.7 10-70 67-174.5 67S34.2 408.5 9.7 379c-6.8-7.7 1-11.3 5.5-8.3C88.5 415.2 203 488.5 387.7 401c7.5-3.7 13.3 2 5.5 12zm39.8 2.2c-6.5 15.8-16 26.8-21.2 31-5.5 4.5-9.5 2.7-6.5-3.8s19.3-46.5 12.7-55c-6.5-8.3-37-4.3-48-3.2-10.8 1-13 2-14-.3-2.3-5.7 21.7-15.5 37.5-17.5 15.7-1.8 41-.8 46 5.7 3.7 5.1 0 27.1-6.5 43.1z"/>
                  <path fill="#000000" d="M257.2 162.7c-48.7 1.8-169.5 15.5-169.5 117.5 0 109.5 138.3 114 183.5 43.2 6.5 10.2 35.4 37.5 45.3 46.8l56.8-56S341 288.9 341 261.4V114.3C341 89 316.5 32 228.7 32 140.7 32 94 87 94 136.3l73.5 6.8c16.3-49.5 54.2-49.5 54.2-49.5 40.7-.1 35.5 29.8 35.5 69.1zm0 86.8c0 80-84.2 68-84.2 17.2 0-47.2 50.5-56.7 84.2-57.8v40.6z"/>
                </svg>
              </Button>
            </div>
          </div>

          {/* <Button href="https://www.amazon.com/dp/1399812246/ref=cm_sw_r_as_gl_api_gl_i_CWSY31NM2VZ2AH3GHH04?linkCode=ml1&tag=tag02b-20" target="_blank" rel="noopener noreferrer" variant="warning" size="lg" className="btn-accent px-md-4 mb-1 me-1 me-md-4">Order Ebook</Button>
          <Button href="https://www.audible.com/pd/Our-Next-Reality-Audiobook/B0CLD7DW5D" target="_blank" rel="noopener noreferrer" variant="warning" size="lg" className="btn-accent px-md-4 mb-1 me-1 me-md-4">Order Audiobook</Button>
          <Button href="https://www.amazon.com/dp/1399812246/ref=cm_sw_r_as_gl_api_gl_i_CWSY31NM2VZ2AH3GHH04?linkCode=ml1&tag=tag02b-20" target="_blank" rel="noopener noreferrer" variant="warning" size="lg" className="btn-accent mb-1 px-md-4">Pre-Order Book</Button> */}

        </div>
      </div>
    </div>
  );
  }

function LAuthor() {
  return (
    <div className="container-fluid col-12 d-flex row align-items-center justify-content-center">
      <h1 className="d-flex justify-content-center p-0">About Louis</h1>
      <div className="col-8 col-md-5">
        <img src="/img/rosenbergl.png" alt="Louis Rosenberg" className="col-10"/>
        <div className="container row col-12 text-center">
          <a href="https://www.linkedin.com/in/louis-rosenberg/" className="p-2">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="linkedin">
              <title>LinkedIn</title>
              <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"/>
            </svg>
            &emsp;louis-rosenberg
          </a>
        </div>
        </div>
      <div className="col-10 col-md-6 pb-0">
        <p>Louis Rosenberg, PhD is an early pioneer of virtual and augmented reality and a longtime AI researcher. He is currently CEO of Unanimous AI, the Chief Scientist of the Responsible Metaverse Alliance and writes often for major publications about the dangers of emerging technologies. <HashLink to="author#louis"><Button variant="light" size="sm" className="px-2">See More</Button></HashLink></p>
      </div>
    </div>
  );
}

function AuthorHighlight() {
  return(
    <div className="container-fluid col-12 col-lg-7 row align-items-center justify-content-center px-3 sept">
    <div className="container-fluid col-12 row align-items-center justify-content-center">
    {/* <div className="container-fluid col-12 col-lg-7 row align-items-center justify-content-center px-3 sept"> */}
      <h1 className="d-flex justify-content-center pt-4">About Alvin</h1>
      <div className="col-8 col-md-5">
        <img src="/img/agraylin.jpg" alt="Alvin Graylin" />
        <SocialsVert />
      </div>
      <div className="col-10 col-md-6 pb-5">
        <p>Alvin Wang Graylin (汪丛青) is a recognized leader, author, investor, and technology pioneer with over three decades of experience driving innovation in AI, XR, and semiconductors. He currently serves as Global VP of Corporate Development at HTC, following a distinguished tenure as China President from 2016 to 2023. Graylin also holds leadership roles as Chairman of the Virtual World Society, Vice-Chair of the Industry of VR Alliance, and President of the Virtual Reality Venture Capital Alliance. <HashLink to="author#alvin"><Button variant="light" size="sm" className="px-2">See More</Button></HashLink></p>
      </div>
    </div>
    <LAuthor />
    </div>
  );
}
// <CarouselFeature cards={} heading="" shortName="" navlink=""/>
function CarouselFeature(props){
  const carouselItemsSm = props.cards.map((card, i) => <Carousel.Item key={props.shortName+"sm"+i}><div className="d-flex justify-content-center px-3">{card}</div></Carousel.Item>);

  let carouselItemsMd = [];
  for (let i = 0; i < props.cards.length; i = i + 2) {
    if (i+1 >= props.cards.length) {
      carouselItemsMd.push(<Carousel.Item><div className="d-flex justify-content-center px-5">{props.cards[i]}</div></Carousel.Item>);
    } else {
      carouselItemsMd.push(<Carousel.Item><div className="d-flex justify-content-center px-5">{props.cards[i]} {props.cards[i+1]}</div></Carousel.Item>);
    }
  }

  return (
    <>
    {props.cards ?
    <div className="mb-5">
      <div className="row mb-1 d-flex justify-content-center align-items-end">
        <div className="col-md-3 md-appear" />
        <div className="col-12 justify-content-center d-flex col-md-auto justify-content-md-end mb-0">
          <h1 className="text-center">{props.heading}</h1>
        </div>
        <div className="d-flex col-md-3 justify-content-center">
          <HashLink to={props.navlink} className="md-appear"><p className="a">See All {props.shortName}<span className="material-icons">&ensp;arrow_forward_ios</span></p></HashLink>
        </div>
      </div>
    
      <div className="d-flex justify-content-center px-">
        <Carousel variant="dark" className="sm-appear">
          {carouselItemsSm}
        </Carousel>
        <Carousel variant="dark" className="md-appear">
          {carouselItemsMd}
        </Carousel>
      </div>

      <div className="d-flex justify-content-center mt-4">
        <HashLink to={props.navlink}><Button variant="light" size="md" className="px-5 sm-appear">See All {props.shortName}</Button></HashLink>
      </div>
    </div> : ''}
    </>
  );
}

function UpcomingEvents(props) {
  const today = new Date().toISOString().substring(0, 10);
  const upcomingEvents = props.events.filter(event => event.date > today);
  const sortedEvents = upcomingEvents.sort((a, b) => new Date(a.date) - new Date(b.date));
  const eventCards = sortedEvents.map((event, i) => <li className="list-group-item p-3" key={i}><strong>{event.date}</strong> &emsp; {event.preLink ? (<a href={event.preLink} target="_blank" rel="noopener noreferrer"><u>{event.name}</u></a>) : event.name}</li>);

  return(<>
    {eventCards ? 
    <div className="container col-11 col-lg-5 pb-4">
      <h1 className="d-flex justify-content-center">Upcoming Events</h1>
      <div className="d-flex justify-content-center">
        {upcomingEvents.length === 0 && <p>No events listed</p>}
        <ul className="list-group list-group-flush">
          {eventCards}
        </ul>
      </div>
      <div className="d-flex justify-content-center mt-3">
        <Link to="events"><Button variant="light" size="md" className="px-5">View Prior Event Recordings</Button></Link>
      </div>
    </div> : ''}</>
  );
}

export default function Home(props) {
  const today = new Date().toISOString().substring(0, 10);
  let mediaCards = "";
  let reviewCards = "";
  let eventCards = "";

  if (!props.isFetching) {
    // press & media
    let media = props.media.filter(event => event.date <= today);
    media = media.sort((a, b) => new Date(b.date) -  new Date(a.date));
    media = Array.from(media).slice(0, 4);
    mediaCards = media.map((media, i) => <MediaCard name={media.name} date={media.date} type={media.type} program={media.program} host={media.host}  description={media.description} image={media.image} link={media.link} key={"media"+i} />);

    // reviews
    let reviews = props.reviews;
    reviews = reviews.filter(review => review.isFeatured);
    // reviews = Array.from(reviews).slice(0, 4);
    reviewCards = reviews.map((review, i) => <ReviewCard reviewer={review.reviewer} cred={review.cred} image={review.image} quote={review.quote} key={"review"+i}/>);

    // event recordings
    let events = props.events.filter(event => event.date <= today);
    events = events.filter(event => event.isFeatured);
    eventCards = events.map((event, i) => <EventCard name={event.name} date={event.date} location={event.location} description={event.description} link={event.postLink} isPre="" key={"past"+i} />);
  }

  return (
    <main>
      <PromoBanner />
      <div className="container">
        <div className="row d-flex justify-content-center">
          <EmailSignUpBanner emailCallback={props.emailCallback} showConfirm={props.showConfirm} alertType={props.alertType} showConfirmMessage={props.showConfirmMessage} showConfirmCallback={props.showConfirmCallback} />
          {props.isFetching ? '' : <CarouselFeature cards={mediaCards} heading="Recent Press & Media" shortName="Media" navlink="media"/>}
          {props.isFetching ? '' : <CarouselFeature cards={reviewCards} heading="Featured Reviews" shortName="Reviews" navlink="reviews"/>}
          {props.isFetching ? '' : <CarouselFeature cards={eventCards} heading="Featured Speeches" shortName="Speeches" navlink="events#recordings"/>}
          <AuthorHighlight />
          {props.isFetching ? '' : <UpcomingEvents events={props.events}/>}
          <GPT />
        </div>
      </div>
    </main>
  );
}
